/*jslint browser: true */
/*global window, jQuery:false */

//Lenis
const lenis = new Lenis({ lerp: 0.2 });

lenis.on('scroll', () => ScrollTrigger.update());

function raf(time) {
  lenis.raf(time);
  requestAnimationFrame(raf);
}
requestAnimationFrame(raf);
lenis.stop();

//SWUP
const swup = new Swup({ containers: ["#site"], animateHistoryBrowsing: true });

//GSAP
gsap.defaults({ ease: 'power4.out', duration: 0.5 });

//Register effects
gsap.registerEffect({
  name: "fadeSlideUp",
  effect: (targets, config) => {
    return gsap.to(targets, {
      autoAlpha:1, yPercent:0, stagger:config.stagger || 0.04, duration:config.duration,
    });
  },
  defaults: {duration: 1},
  extendTimeline: true
});

gsap.registerEffect({
  name: "fadeSlideUpShort",
  effect: (targets, trigger, config) => {
    return gsap.from(targets, {
      autoAlpha:0, yPercent:25, stagger:config.stagger || 0.1,
      scrollTrigger: {
        trigger: trigger,
        start: config.start || 'top bottom-=25%',
        once: true
      }
    });
  },
});

gsap.registerEffect({
  name: "imageParallax",
  effect: (targets, trigger, start) => {
    return gsap.to(targets, {
      'will-change': 'transform', 
      yPercent:15, 
      ease:'none',
      scrollTrigger: {
        trigger: trigger,
        start: start,
        scrub: true,
        invalidateOnRefresh: true,
      }
    });
  },
});

function checkImages(selector, callback) {
  const targetImages = document.querySelector(selector);
    if (targetImages.complete) {
        callback();
    } else {
      targetImages.addEventListener('load', function() {
          callback();
      });
    }
}

function getDataTitle(element) {
  function setTitle() {
    let title = this.getAttribute('data-title');
    document.querySelector('.page-title').textContent = title;
  }
  document.querySelectorAll(element).forEach(function(el) {
    el.addEventListener('click', setTitle);
  });
}

function avoidUnstyledContent() {
  document.getElementById('site').classList.add('init-load');
}

function openMainNav() {
  const buttons = document.querySelectorAll('.menu-btn, .nav-overlay, #menu-main-nav a'),
        menuBtn = document.querySelector('.menu-btn');

  const menuOpenTl = gsap.timeline({ 
    defaults: { 
      duration: 0.8, ease: 'power3.inOut'
    },
    paused: true,
  })
  .set('.nav-panel', { autoAlpha: 1 })
  .from('.nav-overlay', { autoAlpha:0, }, 0)
  .from('.nav-panel', { xPercent: 100, }, 0)
  .from('.nav-content', { xPercent: -50 }, 0)
  .reverse();

  let isLenisPlaying = true;

  function navFn() {
    menuBtn.classList.toggle('is--active');

    menuOpenTl.reversed(!menuOpenTl.reversed());
  
    isLenisPlaying ? lenis.stop() : lenis.start();
    isLenisPlaying = !isLenisPlaying;
  }
  
  buttons.forEach(button => {
    button.addEventListener('click', navFn);
  });
  
  document.addEventListener('keydown', event => {
    if (event.key === 'Escape' && menuBtn.classList.contains('is--active')) {
      navFn();
    }
  });

}

function updateActiveNavItem() {
  const currentPath = window.location.pathname;
  const navItems = document.querySelectorAll('.menu li');

  navItems.forEach(item => {
    const link = item.querySelector('a');
    const linkPath = new URL(link.href).pathname;

    if (linkPath === currentPath) {
      item.classList.add('current-menu-item');
    } else {
      item.classList.remove('current-menu-item');
    }
  });
}

function openModal() {
  const buttons = document.querySelectorAll('.drawer-btn, .drawer-overlay'),
        main = document.querySelector('#site');
  
  const drawerOpenTl = gsap.timeline({ 
    paused:true,
  })
    .set('.drawer', { autoAlpha:1 })
    .from('.drawer-overlay', { autoAlpha:0 }, 'drawerAppears')
    .from('.drawer-content', { scale:0, yPercent:100 }, 'drawerAppears')
    .from('.manifesto-content p', { autoAlpha:0, y:'2.4rem', stagger:0.05 }, 'drawerAppears+=0.3')
    .reverse();

  let isLenisPlaying = true;

  function drawerFn() {
    main.classList.toggle('drawer--active');

    const duration = drawerOpenTl.reversed() ? 1.5 :1;
    drawerOpenTl.duration(duration).reversed(!drawerOpenTl.reversed());
    
    isLenisPlaying ? lenis.stop() : lenis.start();
    isLenisPlaying = !isLenisPlaying;
  }
  
  buttons.forEach(button => { 
    button.addEventListener('click', drawerFn);
  });
  
  document.addEventListener('keydown', event => {
    if (event.key === 'Escape' && main.classList.contains('drawer--active')) {
      drawerFn();
    }
  });
}

function sectionReveal(target, trigger) {
  gsap.set(target, { yPercent: -50 })
  gsap.to(target, { 
    'will-change': 'transform', 
    yPercent: 0, ease: 'none',
    scrollTrigger: { 
      trigger: trigger,
      start: 'top bottom', end: 'bottom bottom',
      scrub: true, invalidateOnRefresh: true,
    },
  });
}

function loadFooter() {

  const hour = new Date().getHours(),
        time = document.getElementById('get-time');

  time.textContent = `${hour < 12 ? 'Good Morning' : hour < 18 ? 'Good Afternoon' : 'Good Evening'},`;

  const footer = document.querySelector('.footer'),
        footerWrap = document.querySelector('.footer-wrap'),
        wordsSplit = footer.querySelector('.h1');
  
  Splitting({ target: wordsSplit, by: 'words' });

  const headerWords = footerWrap.querySelectorAll('.word'),
        footerRest = footerWrap.querySelectorAll('a, p');

  sectionReveal(footerWrap, footer);
  
  let footerContent = gsap.timeline({
    defaults: { 
      autoAlpha:0, y:'2.4rem',
    },
    scrollTrigger: {
      trigger: footer,
      start:'top bottom-=25%',
      toggleActions: 'play reverse play reverse',
    }
  })
  .from(time, {} )
  .from(headerWords, { stagger:0.01, }, '0.3' )
  .from(footerRest, { stagger:0.1 }, '0.4' );

}

function loadPostNav() {

  const posts = document.querySelector('.footer'),
        postsWrap = posts.querySelector('.footer-wrap'),
        post = posts.querySelectorAll('.post-nav');

  sectionReveal(postsWrap, posts);
        
  gsap.from(post, {
    autoAlpha:0, yPercent:25, stagger:0.1,
    scrollTrigger: {
      trigger: posts,
      start:'top bottom-=25%',
      toggleActions: 'play reverse play reverse',
    }
  });

  getDataTitle('.post-nav');

}

function projectCard() {

  function movePopup(e) {
    const img = this.querySelector('img');
    const popup = this.querySelector('.popup');
    const offset = img.getBoundingClientRect();
    let x, y;
  
    if (e.touches && e.touches.length > 0) {
      x = e.touches[0].clientX;
      y = e.touches[0].clientY;
    } else {
      x = e.clientX || 0;
      y = e.clientY || 0;
    }
  
    popup.style.left = `${x - 52 - offset.left}px`;
    popup.style.top = `${y - 104 - offset.top}px`;
  }
  
  document.querySelectorAll('.c-project-card a').forEach(entryImg => {
    entryImg.addEventListener('mousemove', movePopup, { passive: true });
    entryImg.addEventListener('touchmove', movePopup, { passive: true });
  });

  getDataTitle('.project-link, .c-project-card a');

}

function borderSection() {

  const sectionHeaders = document.querySelectorAll('.section-header');

  sectionHeaders.forEach((section) => {
    const line = section.querySelector('.line');

    gsap.to(line, {
      scale:1, duration:1, ease:'power3.inOut',
      scrollTrigger: {
        trigger: section, once: true,
      },
    });
  });
  
}

function loadHome() {

  const wordsSplit = document.querySelectorAll('.m-home-hero-headline, .m-home-about h3 span');
  
  Splitting({ target: wordsSplit, by: 'words' });

  const homeHeroTrigger = document.querySelector('.home-hero');
  const homeHeroTarget = homeHeroTrigger.querySelectorAll('h1, .word');
  const container = document.querySelectorAll('.m-home-feat-projects');

  gsap.set(homeHeroTarget, { autoAlpha:0, yPercent:25 });
  gsap.set(container, { yPercent:2 });

  //Home Hero
  const homeHeroTl = gsap.timeline({
    scrollTrigger: {
      trigger: homeHeroTrigger,
      start:'top center',
      end:'bottom top+=25%',
      toggleActions: 'play reverse play none',
      onEnter: () => {
        gsap.to(container, {
          autoAlpha:1, yPercent:0, delay:0.5
        });
      }
    }
  });

  homeHeroTl.fadeSlideUp(homeHeroTarget, { stagger: 0.05 });

  projectCard();
  borderSection();

  // Expertise
  const expertiseTrigger = document.querySelector('.expertise-list');
  const expertiseTarget = expertiseTrigger.querySelectorAll('li');

  gsap.effects.fadeSlideUpShort(expertiseTarget, expertiseTrigger, {}); 

  //Projects Loop
  gsap.utils.toArray('.t-scroll-home .c-project-card').forEach(projCard => {

    const image = projCard.querySelector('img');
    const imageWrap = projCard.querySelector('a');

    gsap.effects.imageParallax(image, imageWrap);

  });

  //Copy Breaks
  const charsSplit = document.querySelectorAll('.m-home-copy-break p');
  
  Splitting({ target: charsSplit, by: 'chars' });

  document.querySelectorAll('.copy-break--sticky').forEach((section) => {

    const characters = section.querySelectorAll('.char');

    function setStickyTop() {
      const divHeight = section.offsetHeight;
      const topValue = `calc((90vh - ${divHeight}px) / 2)`;
      section.style.top = topValue;
    }
    
    setStickyTop();

    gsap.timeline({
      scrollTrigger: {
        trigger: section.parentNode,
        start: 'top top+=20%',
        end: 'bottom bottom',
        scrub: true,
        invalidateOnRefresh: true,
        onRefresh: setStickyTop,
      }
    })
    .fromTo(characters, { opacity: 0.1, willChange: 'opacity' }, { opacity: 1, stagger: 2 });

    window.addEventListener('resize', setStickyTop);

  });

  
  // About
  const aboutTrigger = document.querySelector('.m-home-about h3'); 
  const aboutText = aboutTrigger.querySelectorAll('.word');
  const aboutImagesWrap = aboutTrigger.querySelectorAll('figure');
  const aboutImagesSpan = aboutTrigger.querySelectorAll('figure div');
  const aboutImages1 = aboutTrigger.querySelectorAll('img:first-of-type');
  const aboutImages2 = aboutTrigger.querySelectorAll('img:nth-of-type(2)');
  const aboutImages3 = aboutTrigger.querySelectorAll('img:nth-of-type(3');

  gsap.effects.fadeSlideUpShort(aboutText, aboutTrigger, {});

  function firstImages() {
    let tl = gsap.timeline();
    tl
    .set(aboutImages1, { zIndex:2, autoAlpha:1 })
    .set([aboutImages2, aboutImages3], { zIndex:1, autoAlpha:0 })
    .from(aboutImagesWrap, { scale: 0, stagger: 0.5 }, '0')
    .from(aboutImagesSpan, { scale: 2, stagger: 0.5 }, '0')
    .to(aboutImagesWrap, { scale: 0, ease: 'power4.in' }, '3')
    .to(aboutImagesSpan, { scale: 2, ease: 'power4.in' }, '<')
    
    return tl;
  }

  function secondImages() {
    let tl = gsap.timeline();
    tl
    .set(aboutImages2, { zIndex:2, autoAlpha:1 })
    .set([aboutImages1, aboutImages3], { zIndex:1, autoAlpha:0 })
    .to(aboutImagesWrap, { scale: 1, stagger: 0.5 }, '0')
    .to(aboutImagesSpan, { scale: 1, stagger: 0.5 }, '0')
    .to(aboutImagesWrap, { scale: 0, ease: 'power4.in' }, '3')
    .to(aboutImagesSpan, { scale: 2, ease: 'power4.in' }, '<')
    
    return tl;
  }

  function thirdImages() {
    let tl = gsap.timeline();
    tl
    .set(aboutImages3, { zIndex:2, autoAlpha:1 })
    .set([aboutImages1, aboutImages2], { zIndex:1, autoAlpha:0 })
    .to(aboutImagesWrap, { scale: 1, stagger: 0.5 }, '0')
    .to(aboutImagesSpan, { scale: 1, stagger: 0.5 }, '0')
    .to(aboutImagesWrap, { scale: 0, ease: 'power4.in' }, '3')
    .to(aboutImagesSpan, { scale: 2, ease: 'power4.in' }, '<')
    
    return tl;
  }

  let imageShowMaster = gsap.timeline({
    repeat: -1,
    delay:0.2,
    scrollTrigger: { 
      trigger: aboutTrigger, 
      start: 'top bottom-=25%',
      toggleActions: "play pause resume pause",
    },
  })
  .add(firstImages())
  .add(secondImages())
  .add(thirdImages());

  
  //Reverse Exit animation
  swup.on('animationOutStart', function(){
    homeHeroTl.reverse();
  });

}

function loadProject() {

  const projectHeroTrigger = document.querySelector('.project-hero');
  const projectHeroTarget = projectHeroTrigger.querySelectorAll('span, h1, p');
  const imageContainer = projectHeroTrigger.querySelector('.project-img');
  const image = imageContainer.querySelector('img');
  
  // Hero Image Parallax
  gsap.fromTo(image, {
    yPercent: -15,
  }, {
    yPercent: 15, scale: 1.2,
    'will-change': 'transform',
    ease: 'none',
    scrollTrigger: {
      trigger: imageContainer,
      start: 'top bottom',
      scrub: true,
      invalidateOnRefresh: true,
      onEnter: () => {
        gsap.from(imageContainer, { 
          clipPath: 'inset(100% 0 0 0)', 
          duration: 1, delay: 0.5,
        });
        gsap.from(projectHeroTarget, { 
          opacity: 0, yPercent: 25, 
          stagger: 0.3, duration: 1, delay: 1,
        });
      },
    }
  });

  //Sliders
  const swiperContainers = document.querySelectorAll('.swiper');

  swiperContainers.forEach(function(container) {
    
    let swiper = new Swiper(container, {
      loop: true,
      speed: 800,
      effect: 'creative',
      creativeEffect: {
        prev: {
          translate: ["-75%", 0, -1],
        },
        next: {
          translate: ["100%", 0, 0],
        },
      },
      pagination: {
        el: container.querySelector('.swiper-counter'),
        type: 'custom',
        renderCustom: function (swiper, current, total) {
          return '0' + current + ' &mdash; 0' + total;
        },
      },
      navigation: {
        nextEl: container.querySelector('.swiper-next'),
        prevEl: container.querySelector('.swiper-prev'),
      },
    });

    // Event delegation for .nav-next and .nav-prev
    container.addEventListener('click', function(event) {
      var target = event.target;
      if (target.classList.contains('nav-next')) {
        swiper.slideNext();
      } else if (target.classList.contains('nav-prev')) {
        swiper.slidePrev();
      }
    });

  });

  borderSection();

  const galleryItems = document.querySelectorAll('.gallery-item');
  galleryItems.forEach(section => {
    gsap.set(section, {
      scrollTrigger: {
        trigger: section, once: true, toggleClass: 'is--active',
      }
    });
  });

  //Full Width Image Bleed
  const imgBleedSections = document.querySelectorAll('.bleed');

  imgBleedSections.forEach(section => {
    const img = section.querySelector('img');
    gsap.from(img, {
      scale:1.2,
      ease: 'none',
      scrollTrigger: {
        trigger: section, scrub: true
      }
    });
  });

  //Quotes
  const quoteSections = document.querySelectorAll('.i-quote');

  quoteSections.forEach(section => {
    const wordsSplit = section.querySelectorAll('h2, cite');
    Splitting({ target: wordsSplit, by: 'words' });

    const text = section.querySelectorAll('.word');
    gsap.effects.fadeSlideUpShort(text, section, { stagger: 0.02, start:'center bottom-=25%' }); 
  });

  //Videos
  const videoSections = document.querySelectorAll('.i-video');

  videoSections.forEach(section => {
    const video = section.children[0].children[0];
    gsap.set(section, {
      scrollTrigger: {
        trigger: section,
        onEnter: () => {
          window.requestAnimationFrame(() => {
            if (video.paused) {
              video.play();
            }
          });
        },
        onLeave: () => {
          window.requestAnimationFrame(() => {
            if (!video.paused) {
              video.pause();
            }
          });
        },
        onEnterBack: () => {
          window.requestAnimationFrame(() => {
            if (video.paused) {
              video.play();
            }
          });
        },
        onLeaveBack: () => {
          window.requestAnimationFrame(() => {
            if (!video.paused) {
              video.pause();
            }
          });
        },
      }
    });
  });

  //Code
  const codeSections = document.querySelectorAll('.i-code');

  codeSections.forEach(section => {
    gsap.set(section, {
      scrollTrigger: {
        trigger: section,
        toggleClass: 'is--running',
        toggleActions: 'play reverse play none',
      }
    });
  });
}

function loadAbout() {

  const charsSplit = document.querySelectorAll('.m-about-hero-headline span');
  Splitting({ target: charsSplit, by: 'chars' });

  const aboutHeroTrigger = document.querySelector('.about-hero');
  const aboutHeroTarget = aboutHeroTrigger.querySelectorAll('h1, .char');
  const editorialContent = document.querySelectorAll('.img-main, .txt-main');

  gsap.set(aboutHeroTarget, { autoAlpha:0, yPercent:25 });
  gsap.set(editorialContent, { autoAlpha:0, yPercent:4 });

  //About Hero
  const aboutHeroTl = gsap.timeline({
    scrollTrigger: {
      trigger: aboutHeroTrigger,
      start: 'top center',
      end: 'bottom top+=25%',
      toggleActions: 'play reverse play none',
      onEnter: () => {
        gsap.to(editorialContent, { 
          autoAlpha:1, yPercent:0, delay:0.4
        });
      }
    }
  });
  aboutHeroTl.fadeSlideUp(aboutHeroTarget, {});

  borderSection();

  //Services
  const serviceGallery = document.querySelector('.service-gallery');
  const [galleryImg1, galleryImg2, galleryImg3] = serviceGallery.querySelectorAll('img');
  const contentElements = [...document.querySelectorAll('.service-card')];
  const totalContentElements = contentElements.length;

  gsap.timeline({
    defaults: {
      'will-change': 'opacity, transform',
      opacity:0, yPercent:25, duration:1,
    },
    scrollTrigger: {
      trigger: serviceGallery,
      start: 'top center',
      end: 'bottom top+=25%',
    }
  })
  .fromTo(galleryImg1, { xPercent:20 }, { opacity:1, xPercent:0, yPercent:0, }, 0)
  .from(galleryImg2, { }, '-=0.5')
  .fromTo(galleryImg3, { xPercent:-20 }, { opacity:1, xPercent:0, yPercent:0 }, '-=0.5');

  contentElements.forEach((section, el, position) => {
    
    const listItems = section.querySelectorAll('li');
    const isLast = position === totalContentElements - 1;
  
    // Timeline for list items within the section
    gsap.timeline({
      scrollTrigger: {
        trigger: section,
        start: 'top center',
      }
    })
    .from(listItems, {
      'will-change': 'opacity, transform',
      opacity: 0,
      yPercent: 25,
      stagger: 0.1,
    });
  
    // Timeline for the section itself
    gsap.timeline({
      scrollTrigger: {
        trigger: section,
        start: 'top top',
        end: '+=100%',
        scrub: true,
      }
    })
    .to(section, {
      ease: 'none',
      startAt: { filter: 'brightness(98%)' },
      filter: isLast ? 'none' : 'brightness(90%)',
      scale: isLast ? 1 : 0.8,
      yPercent: isLast ? 0 : -20,
    }, 0)
    // Animate inner elements
    // .to(el.querySelector('.polaroid'), {
    //   ease: 'power3.in',
    //   yPercent:-10,
    //   xPercent:10,
    //   rotate:8,
    // }, 0)
  });

  //Clients
  const clientsWrap = document.querySelector('.m-about-clients');

  const aboutClientsMarqueeTl = gsap.timeline({
    defaults: { duration: 25, ease: 'none' },
    repeat: -1,
    scrollTrigger: {
      trigger: clientsWrap,
      toggleActions: 'play pause resume pause'
    }
  });
  aboutClientsMarqueeTl.from('.marquee > span', { xPercent: 100 });

  document.querySelectorAll('.marquee').forEach(function(el) {
    el.addEventListener('mouseenter', () => gsap.to(aboutClientsMarqueeTl, {timeScale: 0, overwrite: true}));
    el.addEventListener('mouseleave', () => gsap.to(aboutClientsMarqueeTl, {timeScale: 1, overwrite: true}));
  });

  //Counter

  // Function to generate an array of random numbers between 0 and 9
  function generateRandomNumbers(count) {
    return Array.from({ length: count }, () => Math.floor(Math.random() * 10));
  }

  // Function to calculate yPercent based on the number of spans
  function calculateYPercent(numSpans) {
    return (numSpans - 1) * 100;
  }

  const counterWrap = document.querySelector('.total');
  const [digit1, digit2] = counterWrap.querySelectorAll('.digit1, .digit2');

  // Generate 20 random numbers for each digit
  const randomNumbers = generateRandomNumbers(20);
  
  // General function to add spans with numbers
  function addSpans(parent, numbers, position) {
    const existingSpan = parent.querySelector('span');
    
    numbers.forEach(number => {
      const newSpan = document.createElement('span');
      newSpan.textContent = number;
      newSpan.setAttribute('aria-hidden', 'true');
      
      if (position === 'before') {
        parent.insertBefore(newSpan, existingSpan);
      } else if (position === 'after') {
        parent.appendChild(newSpan);
      }
    });
  }

  addSpans(digit1, randomNumbers, 'before');
  addSpans(digit2, randomNumbers, 'after');
  
  const digit1span = digit1.querySelectorAll('span');
  const digit2span = digit2.querySelectorAll('span');

  // Calculate yPercent based on number of spans
  const yPercent = calculateYPercent(digit1span.length);

  const aboutClientsCounterTl = gsap.timeline({
    defaults: { duration:2, ease:'quint.inOut' },
    scrollTrigger: { 
      trigger: counterWrap,
      start: 'top bottom-=25%',
    }
  });
  aboutClientsCounterTl
    .from(digit2span, { yPercent: -yPercent })
    .to(digit1span, { yPercent: -yPercent }, '0.1');

  //Experience
  gsap.utils.toArray('.experience-list > li').forEach(expCard => {
    const cardSubCard = expCard.querySelectorAll('li ul li div');
    gsap.from(cardSubCard, {
      opacity:0, yPercent:20, stagger:0.1,
      scrollTrigger: {
        trigger: expCard,
        start: 'top bottom-=25%',
        once: true
      }
    });
  });

  //Banner
  const bannerWrap = document.querySelector('.m-about-all-proj'),
        banner = bannerWrap.querySelector('img');

  gsap.effects.imageParallax(banner, bannerWrap);

  //Manifesto
  openModal();

  //Reverse Exit animation
  swup.on('animationOutStart', function(){
    aboutHeroTl.reverse();
  });

}

function loadIndex() {

  const charsSplit = document.querySelectorAll('h1 span');
  Splitting({ target: charsSplit, by: 'chars' });

  const indexHeroTrigger = document.querySelector('.index-hero');
  const indexHeroTarget = indexHeroTrigger.querySelectorAll('.char, p');
  const container = document.getElementsByClassName('proj-loop')[0];
  const heroLine = indexHeroTrigger.querySelector('.line');
        
  gsap.set(indexHeroTarget, { autoAlpha:0, yPercent:25 });
  gsap.set(container, { yPercent:2 });

  const heroLineTl = gsap.timeline();

  heroLineTl.to(heroLine, { scaleX:1 });

  //Index Hero
  const indexHeroTl = gsap.timeline({
    scrollTrigger: {
      trigger: indexHeroTrigger,
      end:'bottom top+=25%',
      toggleActions: 'play reverse play none',
      onEnter: () => {
        gsap.to(container, {
          autoAlpha:1, yPercent:0, delay:0.5
        });
      }
    }
  });
  indexHeroTl.fadeSlideUp(indexHeroTarget, {}).add(heroLineTl, '0.2');

  borderSection();
  projectCard();

  //Project Parallax
  const mm = gsap.matchMedia();
  mm.add("(min-width: 900px)", () => {

    const projCards = gsap.utils.toArray('.c-project-card');

    const projCardsTl = gsap.timeline({
      defaults: {
        ease: 'none',
      },
      scrollTrigger: {
        trigger: container,
        start: 'top center',
        scrub: true,
        invalidateOnRefresh: true,
      }
    });

    projCardsTl.to(projCards, {
      'will-change': 'transform', 
      yPercent: (i) => (i % 3 === 1) ? 30 : -30
    });

  });

  //Reverse Exit animation
  swup.on('animationOutStart', function(){
    indexHeroTl.reverse();
  });

}

function templateJS() {

  const homePage = document.querySelector('.t-scroll-home');
  const portfolioPage = document.querySelector('.t-portfolio');
  const aboutPage = document.querySelector('.t-about');
  const indexPage = document.querySelector('.t-index');

  if (homePage) {
    loadHome(); loadFooter();
  } else if (portfolioPage) {
    loadProject(); loadPostNav();
  } else if (aboutPage) {
    loadAbout(); loadFooter();
  } else if (indexPage) {
    loadIndex(); loadFooter();
  }

}

function initializeLoadingScreen() {
  const loadScreen = document.querySelector('#load-screen');
  const lsPageTitle = loadScreen.querySelector('.page-title');
  const lsLoadingText = loadScreen.querySelector('.loading-txt');

  const loadingScreenOpenTl = gsap.timeline({
    paused: true,
    onStart: () => {
      lsLoadingText.classList.add('is--loading');
    }
  });

  loadingScreenOpenTl
    .from(loadScreen, { autoAlpha: 0 }, 'loadingIn')
    .from(lsPageTitle, { opacity: 0, yPercent: -30, ease: 'power2.out' }, 'titleIn');

  const loadingScreenCloseTl = gsap.timeline({
    paused: true,
    onComplete: () => {
      lsLoadingText.classList.remove('is--loading');
      lenis.start();
    }
  });

  loadingScreenCloseTl
    .to(lsPageTitle, { opacity: 0, yPercent: 20, ease: 'power4.in' }, 'titleOut')
    .to(loadScreen, { autoAlpha: 0 }, 'loadingOut');

  return {
    open: () => loadingScreenOpenTl.restart(),
    close: () => loadingScreenCloseTl.restart(),
  };
}

const loadingScreen = initializeLoadingScreen();

swup.on('clickLink', e => {
  if (e.delegateTarget.matches('.c-project-card a, .post-nav')) {
    loadingScreen.open();
  }
});

swup.on('willReplaceContent', function() { 
  ScrollTrigger.refresh(); 
});

swup.on('contentReplaced', function() {
  lenis.stop();
  updateActiveNavItem();

  checkImages('#site img:first-of-type', function() {
    avoidUnstyledContent();
    window.scrollTo(0, 0);
    if (document.querySelector('.loading-txt').classList.contains('is--loading')) {
      setTimeout(function () {
        loadingScreen.close();
        templateJS();
      }, 1000);
    } else {
      templateJS();
      lenis.start();
    }
  });
});

document.addEventListener('DOMContentLoaded', function() {
  openMainNav();
  updateActiveNavItem();

  checkImages('#site img:first-of-type', function(){
    avoidUnstyledContent();
    templateJS();
    lenis.start();
  });

  //Accessibility
  function handleFirstTab(e) {
    if (e.keyCode === 9) {
      document.body.classList.add('user-is-tabbing');
      window.removeEventListener('keydown', handleFirstTab);
      window.addEventListener('mousedown', handleMouseDownOnce);
    }
  }
  function handleMouseDownOnce(){
    document.body.classList.remove('user-is-tabbing');
    window.removeEventListener('mousedown', handleMouseDownOnce);
    window.addEventListener('keydown', handleFirstTab);
  }
  window.addEventListener('keydown', handleFirstTab);

});
